<template>
  <div class="ask-page">
    <v-row justify="center">
      <v-col md="6">
       <intract-smart-list v-if="shouldProceed" paginated ref="ask-smart-list" class="mt-4"
        :endpoint="askEndpoint" :item-options="{'title': 'title'}">
            <template v-slot:list-item="{ item: questionItem }">
                <div class="posts-container">
                <v-card class="my-4 mx-2 post-card"
                  @click="
                    $router.push({
                      name: 'Question',
                      params: {
                        questionId: questionItem.id,
                      },
                    })"
                >
                    <v-list-item>
                      <v-list-item-avatar>
                          <v-img :src="questionItem.user.image"> </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                          <v-list-item-title> {{ questionItem.user.full_name }} </v-list-item-title>
                          <v-list-item-subtitle> {{ questionItem.user.username }} | {{ questionItem.room.sections }} </v-list-item-subtitle>
                      </v-list-item-content>

                        <v-list-item-icon class="align-self-center mr-n3">
                            <intract-drop-down-menu 
                              v-if="(questionItem.user.id == currentUser.id) || currentUser.is_faculty || currentUser.is_admin"
                              :options-list="getDropdownOptions(questionItem)"
                            />
                        </v-list-item-icon>

                    </v-list-item> 

                    <v-card-text class="pb-3 pt-0">
                        <!-- <div class="black--text subtitle-1">{{ questionItem.title }}</div>
                        <v-list-item-subtitle> {{questionItem.description}} </v-list-item-subtitle> -->

                        <div class="black--text subtitle-1 font-weight-medium">{{ questionItem.title }}</div>
                        <div class="black--text caption-1"
                        v-html="TextHelper.linkify(questionItem.description)"
                        >
                        </div>

                        <p class="my-0 caption grey--text mt-1">
                            {{ moment(questionItem.created).fromNow() }}
                        </p>
                        
                        <!-- V-chips to display whom the question is asked to and number of answers -->
                        <v-row>
                          <v-col>
                            <v-chip 
                              v-if="questionItem.asked_to.length == 1"
                              outlined
                              @click.stop="$set(questionItem, 'showAskedToList', true)" 
                              small class="mt-1 mb-n2 mr-2" color="green">
                              Asked to: {{ questionItem.asked_to[0].full_name }}
                            </v-chip>
                            <v-chip 
                              v-else-if="questionItem.asked_to.length > 1"
                              outlined
                              @click.stop="$set(questionItem, 'showAskedToList', true)" 
                              small class="mt-1 mb-n2 mr-2" color="green">
                              Asked to: {{ questionItem.asked_to[0].full_name }} & {{ questionItem.asked_to.length - 1}} others
                            </v-chip>
                            <v-chip outlined small color="green" class="mt-1 mb-n2">
                              {{ questionItem.answers.length}} answers
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                        
                        <!-- Asked To List -->
                        <intract-list-sheet
                            title="Asked to"
                            description="The question is asked to the following faculty."
                            :visible="questionItem.showAskedToList"
                            :list="showAskedToFaculty(questionItem)"
                            @close="$set(questionItem, 'showAskedToList', false)">
                        </intract-list-sheet>
              

                        <!-- Answer button -->
                        <v-card-actions class="ml-2">
                          <v-btn v-if="currentUser.is_faculty || currentUser.is_admin" 
                            color="primary" elevation="1" 
                            small 
                            class="non-focused"
                          >
                            answer
                          </v-btn>
                        </v-card-actions>
                </v-card>
                </div>
            </template>       
        </intract-smart-list> 

      </v-col>
    </v-row>

    <v-fab-transition>
      <v-btn
        v-if="!isLoading && currentUser.is_student"
        color="primary"
        class="non-focused"
        @click="questionCreateSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>


    <!-- ASK QUESTION CREATE EDIT SHEET -->
    <intract-create-edit-sheet
      persistent
      title="Ask Question"
      submit-button-text="Ask"
      :visible="questionCreateSheet.visible"
      @close="questionCreateSheet.visible = false"
      :fields="questionformFields"
      enable-media
      :endpoint="endpoints.questionViewSet"
      :data-object="questionCreateSheet.obj"
      :create-success-message="questionCreateSheet.createSuccessMessage"
      @objectCreated="questionCreated"
      @updateObject="(obj) => (questionCreateSheet.obj = obj)"
    />

      <!-- Delete question confirmation dialog -->
      <confirmation-dialog
        title="Are you sure you want to delete this Question?"
        description="This action cannot be undone" 
        :visible="showDeleteQuestionDialog"
        delete-dialog
        @successCallback="deleteQuestionItem"
        @failureCallback="showDeleteQuestionDialog = false"
      />  

      <choose-room-dialog
        :visible="showRoomDialog"
        @roomSelected="
          (r) => {
            selectedRoom = r;
            showRoomDialog = false;
          }
        "
    />
  
  </div>
</template>

<script>
import TextHelper from '@utils/text_helper';
import moment from "moment";
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import IntractCreateEditSheet, { CreateEditFieldTypes,} from "@components/generics/IntractCreateEditSheet.vue";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import IntractListSheet from "@components/generics/IntractListSheet";

export default {
    name: "Ask",
    mixins: [Mixins.essentials, Mixins.pullToRefresh],

    components: {
      IntractSmartList,
      ChooseRoomDialog, 
      IntractCreateEditSheet, 
      IntractDropDownMenu,
      IntractListSheet,
    }, 

    data() {
      return {    
        TextHelper,    
        moment: moment,
        selectedRoom: null,
        showRoomDialog: false,
        showDeleteQuestionDialog: false,
        toBeDeletedItem: null,
        showAskedToList: false,
        questionCreateSheet: {
            visible: false,
            createSuccessMessage: "Question sent successfully!",
            editId: null,
            obj: {
                room: null,
                institution: null,
                user: {},
                answer: null, 
                question_images: [],
            },
        },      
      }
    },

    computed: {
      
        shouldProceed() {
          return (
            ((this.currentUser.is_admin || this.currentUser.is_student) && this.selectedRoom) ||
            (this.currentUser.is_faculty && !this.currentUser.is_admin)
          );
        },

        askEndpoint() {
            if (!this.shouldProceed) return;
            
            if (this.currentUser.is_student || this.currentUser.is_admin) {
                console.log(this.Helper.addUrlParams(this.endpoints.questionViewSet, ["room=" + this.selectedRoom.id, "ordering=-created"]))
                return this.Helper.addUrlParams(this.endpoints.questionViewSet, ["room=" + this.selectedRoom.id, "ordering=-created"]);
            }

            // if faculty
                return this.Helper.addUrlParams(this.endpoints.questionViewSet + "faculty/", ["ordering=-created"]);
        }, 

        questionformFields() {
          return {
            title: {
              fieldType: CreateEditFieldTypes.TEXTFIELD,
              label: "Title",
              required: true,
              md: 12,
              max: 255,
            },
            description: {
              fieldType: CreateEditFieldTypes.TEXTAREA,
              label: "Description (optional)",
              md: 12,
              required: false,
              max: 3000,
            },
            asked_to: {
              fieldType: CreateEditFieldTypes.SELECT,
              label: "Add Teachers",
              required: false,
              md: 12,
              endpoint: this.Helper.addUrlParams(
                this.endpoints.staffViewSet,
                "paginate=false"
              ),
              multiple: true,
              itemValue: "id",
              itemText: "full_name",
              items: [],
            },
            question_images: {
              fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
              multiple: true,
              label: "Images",
              helper: "JPG, PNG, GIF, etc",
            },
          };
        },
    }, 

    
    methods: {
        
        showAskedToFaculty(questionItem) {
            return questionItem.asked_to.map(faculty => { 
              return {
                image: faculty.image,
                title: faculty.full_name,
                subtitle: faculty.username,
              };               
            })
        },

        getDropdownOptions(questionItem) {
          const self = this;
          return [
            {
              value: 2,
              text: "Delete",
              icon: "mdi-delete",
              type: "delete",
              action() {
                self.toBeDeletedItem = questionItem;
                self.showDeleteQuestionDialog = true;
              }          
            }
          ];
        },

        async questionCreated() {
          this.$refs["ask-smart-list"].getItems();
        },

        async deleteQuestionItem() {
          var url = this.endpoints.questionViewSet + this.toBeDeletedItem.id + "/";
          var questionId = this.toBeDeletedItem.id;
          this.toBeDeletedItem = null;
          this.showDeleteQuestionDialog = false;
          var response = await this.api.call(
            this.essentials,
            url, 
            this.api.Methods.DELETE
          );
          if (response) {
            this.showSnackbar({
              title: "Question has been successfully deleted!",
              type: "success",
            });
          }         
          this.$refs["ask-smart-list"].removeItem(questionId);
        },
    },

    created() {
        this.showRoomDialog = this.currentUser.is_admin; 

        if (this.currentUser.is_student){
            this.questionCreateSheet.obj.institution = this.currentInstitution.id;
            this.questionCreateSheet.obj.user = this.currentUser.id;
            this.questionCreateSheet.obj.room = this.currentUser.room.id;
        }
    }
}
</script>

<style>

</style>